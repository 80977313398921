import "dygraphs/dist/dygraph.css";

import { dateWindowState, valueRangeState } from "../../state/recoil";
import { useEffect, useRef } from "react";

import Dygraph from "dygraphs";
import config from "../../lib/config";
import { useRecoilState } from "recoil";

const guidesConfig = {
  ground: {
    name: 'Ground level',
    lineDash: [],
    color: config.colors.groundLevel
  },
  screenTop: {
    name: 'Screen top',
    lineDash: [5, 5],
    color: config.colors.pipe
  },
  screenBot: {
    name: 'Screen base',
    lineDash: [5, 5],
    color: config.colors.pipe
  },
}

export const DygraphComponent = ({
  dataType,
  series,
  pointClickCallback,
  yTitle,
  guides,
  selectedDataPoint,
  pointsWithComments,
  pointsWithAnnotations = [],
  defaultDateWindow,
  defaultValueRange,
  visibleSeries,
}) => {
  const myRef = useRef();

  const [dateWindow, setDateWindow] = useRecoilState(dateWindowState(dataType));
  const [valueRange, setValueRange] = useRecoilState(valueRangeState(dataType));

  useEffect(
    (node) => {
      if (myRef.current && series) {
        const graph = new Dygraph(myRef.current, series.seriesData, {
          colors: config.colors.series,
          visibility: visibleSeries.map((m) => m.visible),
          labels: series.labels,
          width: "100%",
          height: 600,
          connectSeparatedPoints: true,
          drawPoints: true,
          pointClickCallback: (event, point) => {
            const pointData = {
              id: series.data[point.idx][1].id,
              series: point.name,
            };
            pointClickCallback(pointData);
          },

          strokeWidth: 1,
          pointSize: 3,
          labelsUTC: true,
          yRangePad: 50,
          xRangePad: 50,
          ylabel: yTitle,
          legend: "follow",
          legendFormatter: (data) => {
            let string = `<b>${data.xHTML}</b> `;
            data.series.forEach((s) => {
              if (s.y !== undefined) {
                string = `${string}<b style="color:${s.color}">${s.labelHTML}</b> : ${s.yHTML}<br/>`;
              }
            });
            return `<div style="width: 225px;border: 1px solid gray; border-radius: 4px; padding: 10px">${string}</div>`;
          },
          y2label: "Rainfall mm/day",
          xlabel: "Date",
          yLabelWidth: 25,
          xLabelHeight: 25,
          zoomCallback: (minDate, maxDate, yRange) => {
            setDateWindow([minDate, maxDate]);
            setValueRange([yRange[0][0], yRange[0][1]]);
          },
          drawPointCallback: (
            g,
            seriesName,
            ctx,
            cx,
            cy,
            color,
            pointSize,
            idx
          ) => {
            ctx.fillStyle = color;
            if (
              selectedDataPoint &&
              selectedDataPoint.series === seriesName &&
              +selectedDataPoint.date === +series.seriesData[idx][0]
            ) {
              ctx.beginPath();
              ctx.arc(cx, cy, pointSize, 0, 2 * Math.PI);
              ctx.stroke();
              ctx.fill();
              ctx.beginPath();
              ctx.arc(cx, cy, pointSize + 3, 0, 2 * Math.PI);
              ctx.lineWidth = 3;
              ctx.stroke();
            } else {
              ctx.beginPath();
              ctx.arc(cx, cy, pointSize, 0, 2 * Math.PI);
              if (seriesName !== 'Rainfall' && !series.data[idx][1]?.is_grouped) {
                ctx.fill();
              }
              ctx.stroke();
            }
          },
          dateWindow: dateWindow || defaultDateWindow,
          valueRange: valueRange || defaultValueRange,
          interactionModel: {
            ...Dygraph.defaultInteractionModel,
            dblclick: () => {
              setDateWindow(defaultDateWindow);
              setValueRange(defaultValueRange);
            },
          },
          underlayCallback: function (ctx, area, g) {
            guides?.forEach((item, index) => {
              const coordY = g.toDomYCoord(item.value);
              // text
              ctx.fillStyle = guidesConfig[item.name].color;
              ctx.fillText(guidesConfig[item.name].name, area.x + 5 + index * 75, coordY - 5);
              // line
              ctx.beginPath();
              ctx.setLineDash(guidesConfig[item.name].lineDash);
              ctx.moveTo(0, coordY);
              ctx.lineTo(ctx.canvas.getBoundingClientRect().right, coordY);
              ctx.strokeStyle = guidesConfig[item.name].color;
              ctx.stroke();
              ctx.closePath();
              ctx.setLineDash([]);
            });
          },
          series: {
            Rainfall: {
              axis: "y2",
            },
          },
          axes: {
            y: {
              axisLabelWidth: 80,
            },
            y2: {
              independentTicks: true,
            },
          },
        });
        graph.ready(() => {
          graph.setAnnotations(
            [
              ...pointsWithAnnotations.map((point) => {
                return {
                  series: point.series,
                  x: +new Date(point.x),
                  cssClass: "dygraphsAnnotation",
                  shortText: `${point.unit || "n/a"}`,
                  text: `${point.unit || "n/a"}`,
                };
              }),
              ...pointsWithComments.map((point) => {
                return {
                  series: point.series,
                  x: +new Date(point.x),
                  cssClass: "dygraphsCommentAnnotation",
                  shortText: `${point.openCommentCount || "✓"}(${point.commentCount
                    })`,
                  text: `Open comments: ${point.openCommentCount},  Total comments: ${point.commentCount}`,
                };
              }),
            ]
          );
        });
      }
    },
    [
      guides,
      series.seriesData,
      series.labels,
      series,
      pointClickCallback,
      yTitle,
      selectedDataPoint,
      pointsWithComments,
      pointsWithAnnotations,
    ]
  );
  return <div ref={myRef}></div>;
};
