import "./app.css";

import * as Sentry from "@sentry/react";

import { Box, Container, Grid } from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Chemicals from "./pages/chemicals";
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import Dashboard from "./pages/waterlevels";
import Disclaimer from "./components/disclaimer";
import { FAQ } from './pages/faq';
import Loading from "./components/loadingScreen";
import Login from "./pages/login";
import { Logout } from "./pages/logout";
import PageLayout from "./layouts/page-layout";
import PasswordChangePage from "./pages/password-change";
import PasswordResetPage from "./pages/password-reset";
import ProtectedRoute from "./components/protected-route/protected-route";
import RegisterPage from "./pages/register";
import UnlockPage from "./pages/unlock";
import UserAdminPage from "./pages/admin/user/user";
import logUserActivity from "./common/user-activity-logging";
import { useAuth } from "./components/auth-provider/auth-provider";
import useUnload from "./hooks/useUnload";
import { userRoles } from "./lib/user-roles";

function App() {
  const { loading, user } = useAuth();

  useUnload(e => {
    logUserActivity({ action: 'APP_CLOSE' })
    e.preventDefault();
  });

  const defaultRoute = user?.id ? (user.allow_waterlevel ? 'waterLevels' : (user?.allow_chemistry ? 'waterChemistry' : 'logout')) : 'login';

  return (
    <Box>
      <CssVarsProvider>
        <Disclaimer></Disclaimer>
        <Container>
          <Grid gap={3} paddingTop={10} justifyContent="center" container>
            <Grid xs={12} item>
              {!loading ? (
                <BrowserRouter>
                  <Routes>
                    <Route path="login" element={<Login />}></Route>
                    <Route
                      path="logout"
                      element={
                        <ProtectedRoute>
                          <Logout />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="register/:id"
                      element={
                        <RegisterPage />
                      }
                    />
                    <Route
                      path="password-reset"
                      element={
                        <PasswordResetPage />
                      }
                    />
                    <Route
                      path="password-change/:id"
                      element={
                        <PasswordChangePage />
                      }
                    />
                    <Route
                      path="unlock/:id"
                      element={
                        <UnlockPage />
                      }
                    />
                    <Route
                      path="waterLevels"
                      element={
                        <ProtectedRoute>
                          <PageLayout
                            page={<Dashboard />}
                            title={"Water Levels"}
                          ></PageLayout>
                        </ProtectedRoute>
                      }
                    ></Route>
                    <Route
                      path="waterChemistry"
                      element={
                        <ProtectedRoute>
                          <PageLayout
                            page={<Chemicals />}
                            title={"Water Chemistry"}
                          ></PageLayout>
                        </ProtectedRoute>
                      }
                    ></Route>
                    <Route
                      path="faq"
                      element={
                        <ProtectedRoute>
                          <PageLayout
                            page={<FAQ />}
                            title={"Frequently asked questions"}
                          ></PageLayout>
                        </ProtectedRoute>
                      }
                    ></Route>
                    <Route
                      path="admin/user"
                      element={
                        <ProtectedRoute roles={[userRoles.admin, userRoles.platform_admin]}>
                          <PageLayout
                            page={<UserAdminPage />}
                            title={"User administration"}
                          ></PageLayout>
                        </ProtectedRoute>
                      }
                    ></Route>
                    <Route
                      path="*"
                      element={<Navigate to={defaultRoute}></Navigate>}
                    ></Route>
                  </Routes>
                </BrowserRouter>
              ) : (
                <Loading />
              )}
            </Grid>
          </Grid>
        </Container>
      </CssVarsProvider>
    </Box>
  );
}

export default Sentry.withProfiler(App);
