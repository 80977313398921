import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  dateWindowState,
  selectedDataPointFamily,
  valueRangeState,
  visibleSeriesState,
} from "../../state/recoil";
import { useEffect, useMemo } from "react";

import { DygraphComponent } from "../dygraphs/dygraphs";
import HomeIcon from "@mui/icons-material/Home";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Stack } from "@mui/system";
import config from "../../lib/config";
import { useRecoilState } from "recoil";

const GraphTooltip = (
  <Stack divider={<hr />}>
    <Typography variant="caption">
      Mouse over to highlight individual values.
    </Typography>
    <Typography variant="caption">
      Select an area to zoom in by clicking and dragging.
    </Typography>
    <Typography variant="caption">
      Pan by holding shift and dragging with a mouse.
    </Typography>
    <Typography variant="caption">
      Reset zoom by double clicking or clicking Home button.
    </Typography>
    <Typography variant="caption">
      Click on a value to open comments. (only allowed when graph type is set to
      All Readings)
    </Typography>
  </Stack>
);

const SeriesChart = ({
  dataType,
  data,
  isReversed,
  guides,
  min,
  max,
  yTitle,
  canSelectPoint,
  pointsWithComments,
  pointsWithAnnotations = [],
  downloadButton,
}) => {
  const [selectedDataPoint, setSelectedDataPoint] = useRecoilState(selectedDataPointFamily(dataType));
  const [visibleSeries, setVisibleSeries] = useRecoilState(visibleSeriesState(dataType));
  const [dateWindow, setDateWindow] = useRecoilState(dateWindowState(dataType));
  const [valueRange, setValueRange] = useRecoilState(valueRangeState(dataType));

  if (visibleSeries.length != data.length) {
    setVisibleSeries(data.map((d) => ({ name: d.name, visible: true })));
  }

  const series = useMemo(() => {
    const dates = {};

    data.map((item, index) => {
      item.data.map((point) => {
        if (dates[point.x]) {
          dates[point.x][index] = point;
        } else if (!isNaN(Date.parse(point.x))) {
          const row = Array(data.length).fill(null);
          row[index] = point;
          dates[point.x] = row;
        }
      });
    });

    const labels = ["Date", ...data.map((item) => item.name)];

    const _data = Object.keys(dates).map((key) => [
      new Date(key),
      ...dates[key],
    ]).sort((a, b) => a[0] - b[0]);

    const seriesData = _data.map(series => series.map((data, i) => i === 0 ? data : data && Number(data.y)));

    return {
      labels,
      data: _data,
      seriesData,
    };
  }, [data]);

  const defaultDateWindow = useMemo(() => {
    if (data.length === 1 && data[0].data.length === 1) {
      // this is needed because dygraphs doesn't like it when the date window is the same as the data point
      return [
        +new Date(data[0].data[0].x) - 1000000000,
        +new Date(data[0].data[0].x) + 1000000000,
      ];
    }
    return undefined;
  }, [data]);

  const defaultValueRange = useMemo(() => {
    const getMax = (arr) => {
      let len = arr.length;
      let max = -Infinity;

      while (len--) {
        max = arr[len] > max ? arr[len] : max;
      }
      return max;
    }

    const getMin = (arr) => {
      let len = arr.length;
      let min = Infinity;

      while (len--) {
        min = arr[len] < min ? arr[len] : min;
      }
      return min;
    }

    const values = series.seriesData
      ?.map((item) => item.slice(1))
      .flat()
      .filter(Number.isFinite);
    const guidesValues = guides.map((m) => m.value).filter(Number.isFinite);
    let max = getMax([...values, ...guidesValues]);
    let min = getMin([...values, ...guidesValues]);

    if (Math.abs(max - min) < 5) {
      max = max + 2;
    }
    return isReversed ? [max, min] : [min, max];
  }, [guides, isReversed, series.seriesData]);


  const DownloadButton = downloadButton;

  useEffect(() => {
    setValueRange(defaultValueRange || undefined);
  }, [guides, isReversed, series.seriesData]);

  return (
    <>
      {(data.length && (
        <Card>
          <CardContent>
            <Stack
              paddingX={2}
              paddingY={2}
              justifyContent={"flex-end"}
              direction="row"
            >
              {DownloadButton && <DownloadButton />}

              <IconButton
                onClick={() => {
                  setDateWindow(defaultDateWindow);
                  setValueRange(defaultValueRange);
                }}
              >
                <HomeIcon></HomeIcon>
              </IconButton>

              <Tooltip title={GraphTooltip}>
                <IconButton>
                  <QuestionMarkIcon></QuestionMarkIcon>
                </IconButton>
              </Tooltip>


            </Stack>
            <DygraphComponent
              dataType={dataType}
              min={min}
              max={max}
              yTitle={yTitle}
              defaultDateWindow={defaultDateWindow}
              defaultValueRange={defaultValueRange}
              invertY={isReversed}
              series={series}
              guides={guides}
              // hack to prevent errors when the visible series is not the same length as the data during initial render
              visibleSeries={
                visibleSeries.length === data.length
                  ? visibleSeries
                  : data.map((d) => ({ name: d.name, visible: true }))
              }
              pointsWithComments={pointsWithComments}
              pointsWithAnnotations={pointsWithAnnotations}
              selectedDataPoint={selectedDataPoint}
              pointClickCallback={(pointData) => {
                if (!canSelectPoint || pointData.series === "Rainfall") {
                  return;
                }

                const series = data.find((d) => d.name === pointData.series);
                const originalPoint = series.data.find((p) => p.id === pointData.id);

                setSelectedDataPoint({
                  id: originalPoint.id,
                  boreholeId: series.name.split("#")[0],
                  boreholePipeId: series.name,
                  series: series.name,
                  date: new Date(originalPoint.x),
                  value: originalPoint.y,
                  threads: originalPoint.threads
                });
              }}
            />
            <Box marginTop={3} sx={{ textAlign: "center" }}>
              {visibleSeries.map((s, index) => {
                return (
                  <Box
                    key={s.name}
                    marginLeft={3}
                    sx={{ display: "inline-block" }}
                  >
                    <Typography
                      onClick={() => {
                        // copy array to unfreeze it
                        const newSeries = JSON.parse(
                          JSON.stringify(visibleSeries)
                        );
                        newSeries[index].visible = !newSeries[index].visible;
                        setVisibleSeries(newSeries);
                      }}
                      sx={{
                        cursor: "pointer",
                        textDecoration: !s.visible && "line-through",
                      }}
                      variant="caption"
                    >
                      <div
                        className="series-color"
                        style={{
                          backgroundColor: config.colors.series[index],
                          height: "10px",
                          width: "10px",
                          display: "inline-block",
                          marginRight: "5px",
                          borderRadius: "50%",
                        }}
                      ></div>
                      {s.name}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </CardContent>
        </Card>
      )) || <>No data</>}
    </>
  );
};

export default SeriesChart;
